<i18n locale="th" lang="yaml" >
page.title : "Pending Tickets"
page.description : "รายการของ Ticket ที่รอข้อมูลเพิ่มเติม ซึ่งอาจเป็นเพราะหาสาเหตุหลักยังไม่พบ หรือ ข้อมูลไม่เพียงพอ"
</i18n>
<template>
	<div class="page">
		<div class="page-action-right">
			<a-button icon="left" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<my-page-header
		:title="$t('page.title',{ name: drone.name })"
		:description="$t('page.description',{ name: drone.name })"/>

		<a-card :bordered="false">
			<TicketTableMode
				mode="pending"
				filter-name="droneInstanceId"
				:hide-columns="['drone']"
				:filter-value="drone.id"/>
		</a-card>
	</div>

</template>

<script>
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
export default {
	components : {
		TicketTableMode,
	} ,
	props : {
		droneData : {
			type : Object,
			default : () => []
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		}
	} ,
	methods : {
		goBack() {
			this.$open({name: 'drone/view' , params: {serial_no : this.drone.serialNo}})
		}
	}
}
</script>
